import './style.scss'
import * as $ from 'jquery'
import 'slick-carousel'

const $$button = document.querySelector('#product-component-2dc9c5a047f')
const $$mobileButton = document.querySelector('#mobile-button-wrap')
const $$desktopButton = document.querySelector('#desktop-button-wrap')

function marquee() {
    let $marquee = $('#marquee-slideshow')
    let $slide = $marquee.children().eq(0)
    let $newSlide = $slide.clone()
    $newSlide.clone().appendTo($marquee)
    $newSlide.clone().appendTo($marquee)
    $marquee.slick({
        autoplay: true,
        autoplaySpeed: 0,
        arrows: false,
        infinite: true,
        speed: 15000,
        variableWidth: true,
        centerMode: true,
        cssEase: 'linear',
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    speed: 8000
                }
            }
        ]
    })
}
function slideshow() {
    let $slideshow = $('#slideshow')
    $slideshow
        .slick({
            infinite: true,
            speed: 300,
            cssEase: 'linear',
            lazyLoad: 'ondemand'
        })
        .on('setPosition', function(event, slick) {
            slick.$slides.css('height', slick.$slideTrack.height() + 'px')
        })
}

function bubbles() {
    setTimeout(function() {
        $('.bubble-1').removeClass('hide')
    }, 700)
    setTimeout(function() {
        $('.bubble-2').removeClass('hide')
    }, 900)
    setTimeout(function() {
        $('#open-modal').removeClass('hide')
    }, 1100)
    setTimeout(function() {
        $('#waitlist-form').removeClass('hide')
    }, 1400)
}

function setupModal() {
    let $a = $('#open-modal')

    let $m = $('#modal')
    let $b = $('.background', $m)
    let $c = $('.modal-footer', $m)

    function closeModal() {
        $m.fadeOut(200)
        $a.fadeIn(200)
    }
    function openModal() {
        $m.fadeIn(200)
        $a.fadeOut(200)
    }

    $m.hide()
    $b.click(closeModal)
    $c.click(closeModal)
    $a.click(openModal)
}

function positionModal() {
    let $a = $('#open-modal')
    let $m = $('#modal')
}

let mode = 'desktop'

async function main() {
    $(document).ready(function() {
        slideshow()
        marquee()
        bubbles()
        setupModal()
    })
    size()
}

main()

window.addEventListener(
    'touchstart',
    function onFirstTouch() {
        document.body.classList.add('is-touch')
        window.removeEventListener('touchstart', onFirstTouch, false)
    },
    false
)

function size() {
    positionModal()

    // if (window.innerWidth < 767) {
    //     if (mode === 'desktop') {
    //         mode = 'mobile'
    //         $$button.remove()
    //         $$mobileButton.appendChild($$button)
    //     }
    // } else {
    //     if (mode === 'mobile') {
    //         mode = 'desktop'
    //         $$button.remove()
    //         $$desktopButton.appendChild($$button)
    //     }
    // }
}

window.addEventListener('resize', size)
